import React from "react"

import travolta from '../images/404.gif';

const NotFoundPage = () => (
  <div style={{
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
  }}>
    <img src={travolta} alt="oopsie poopsie" />
  </div>
)

export default NotFoundPage
